import React, { Dispatch, SetStateAction } from "react";
import {
  Currency,
  PaymentDuration,
  Property,
  PropertyStatus,
  RentDuration,
} from "../../../types/Property";
import Dropdown from "../../FormElements/Dropdown";
import TextArea from "../../FormElements/TextArea";
import TextInput from "../../FormElements/TextInput";
import { ViewProps } from "../Amenities/index.old";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface DescriptionErrorState {
  title: boolean;
  description: boolean;
  price: boolean;
  duration: boolean;
  otherPrices: boolean;
  realEstateName: boolean;
  downPayment: boolean;
}

export interface DescriptionProps extends ViewProps {
  errorState?: DescriptionErrorState;
  unhighlight?: (e: React.ChangeEvent<any>) => void;
  isInstallment?: boolean;
  setIsInstallment?: Dispatch<SetStateAction<boolean>>;
  handleCurrencyChange: (c: Currency | undefined) => void;
  currency: Currency | undefined;
}

const Description: React.FC<DescriptionProps> = ({
  onPrevStep,
  onNextStep,
  prevKey,
  currency,
  nextKey,
  errorState,
  unhighlight,
  isInstallment,
  setIsInstallment,
  handleCurrencyChange,
}) => {
  const [property, setProperty] = React.useState<Property | undefined>();

  const [isRealEstate, setIsRealEstate] = React.useState(false);

  const isForiegnCurrency = currency !== "NGN";

  const statusOptions: PropertyStatus[] = [
    "Rent",
    "Rent To Own",
    "Sale",
    "Mortgage",
  ];

  const statusOptionsForForeign: PropertyStatus[] = ["Sale"];

  //   const currencyOptions = [
  // {name: "NGN Nigerian Naira", value: "NGN"},
  // {name: "USD United States Dollar", value: "USD"},
  //   ];

  const currencyOptions = ["NGN", "USD"];

  const [isRent, setIsRent] = React.useState(false);
  const [isSale, setIsSale] = React.useState(false);

  const repaymentInstallmentArr = [
    // {
    //   label: "",
    //   value: "",
    // },
    {
      label: "6 months",
      value: "6",
    },
    {
      label: "12 months",
      value: "12",
    },
    {
      label: "18 months",
      value: "18",
    },
  ];

  const returnRepaymentPeriod = (n: number | undefined) => {
    switch (n) {
      case 1:
        return PaymentDuration.one;
      case 2:
        return PaymentDuration.two;
      case 3:
        return PaymentDuration.three;
      default:
        return "";
    }
  };

  // Listeners
  const subscribe = () => {
    document.addEventListener("updateProduct", (event: CustomEventInit) => {
      setProperty(event.detail.value);
    });
  };
  const unsubscribe = () => {
    document.removeEventListener("updateProduct", (event: CustomEventInit) => {
      setProperty(event.detail.value);
    });
  };

  // Duration Options
  const options: RentDuration[] = ["Annual", "Monthly"];

  // Effect for update mode
  React.useEffect(() => {
    subscribe();
    return unsubscribe();
  }, [property]);

  const handleNextStep = () => {
    if (onNextStep && nextKey) {
      onNextStep(nextKey);
    }
  };

  return (
    <div className="ltn__apartments-tab-content-inner">
      {/** Property Description section */}
      <h6>Property Description</h6>
      <div className="row">
        <div className="col-md-12">
          <TextInput
            name="title"
            useValue={property?.title}
            placeholder="*Title (mandatory), example: 1 bedroom mini-flat for rent"
            error={errorState?.title}
            required={errorState?.title}
            onChange={unhighlight}
          />
          <TextArea
            placeholder="*Description (mandatory) example: This is an executive furnished and serviced mini flat to Let. The renting system here is on a monthly basis."
            name="description"
            value={property?.description}
            error={errorState?.description}
            required={errorState?.description}
            onChange={unhighlight}
          />
        </div>
      </div>

      {/** Property Price */}
      <h6>Property Price</h6>
      <div className="row">
        <div className="col-md-6">
          <Dropdown
            placeholder="Select price currency"
            options={currencyOptions}
            value={currency}
            selectProps={{ required: true }}
            valueCollector={handleCurrencyChange}
          />
        </div>

        <div className="col-md-6">
          <TextInput
            placeholder="Price"
            variant="amount"
            name="price"
            useValue={property?.price}
            error={errorState?.otherPrices}
            required={errorState?.otherPrices}
            onChange={unhighlight}
            currency={currency}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            placeholder="Agreement fee, Agency fee et al"
            variant="amount"
            name="otherPrices"
            useValue={property?.otherPrices}
            error={errorState?.price}
            required={errorState?.price}
            onChange={unhighlight}
            currency={currency}
          />
        </div>

        <div className="col-md-6">
          <Dropdown
            placeholder="Select property status..."
            options={
              isForiegnCurrency === true
                ? statusOptionsForForeign
                : statusOptions
            }
            name="status"
            value={property?.status}
            selectProps={{ required: true }}
            StatusIsRent={setIsRent}
            StatusIsSale={setIsSale}
          />
        </div>

        {isRent === true && (
          <div className="col-md-6">
            <div className="input-item input-item-textarea ltn__custom-icon">
              <Dropdown
                placeholder="Rent Duration*"
                name="duration"
                value={property?.duration}
                options={options}
                selectProps={{ required: true }}
              />
              {/* <TextInput placeholder="After Price Label (ex: /month)" name="afterlabel" useValue={"/month"}/> */}
            </div>
          </div>
        )}

        {isSale === true && (
          <div className="col-md-6">
            <FormControlLabel
              label="Is installmental payment available ?"
              control={
                <Checkbox
                  defaultChecked={property?.isInstallmental}
                  checked={isInstallment}
                  onChange={() =>
                    setIsInstallment && setIsInstallment(!isInstallment)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              }
            />
          </div>
        )}

        {isInstallment === true && (
          <div className="col-md-6">
            <TextInput
              placeholder="Minimum acceptable down payment"
              variant="amount"
              name="downpayment"
              useValue={property?.downPayment}
              error={errorState?.downPayment}
              required={errorState?.downPayment}
              currency={currency}
              onChange={unhighlight}
            />
          </div>
        )}

        {isInstallment === true && (
          <div className="col-md-6">
            <Dropdown
              placeholder="Acceptable duration of installments"
              name="repayment"
              value={returnRepaymentPeriod(property?.paymentDuration)}
              selectProps={{ required: true }}
              optionsObj={repaymentInstallmentArr}
              useObjectValues
            />
          </div>
        )}

        <div className="col-md-6">
          {isRealEstate ? (
            <TextInput
              placeholder="Enter the name of your real estate firm."
              name="realEstateName"
              value={property?.realEstateName}
              error={errorState?.realEstateName}
              // required={errorState?.description}
              onChange={unhighlight}
            />
          ) : (
            <FormControlLabel
              label="Are you a real estate firm ?"
              control={
                <Checkbox
                  checked={isRealEstate}
                  onChange={() => setIsRealEstate(!isRealEstate)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          )}
          {/* </div> */}
        </div>
      </div>

      <div className="btn-wrapper text-center--- mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          onClick={handleNextStep}
        >
          Next Step
        </button>
      </div>

      {/** Select Categories */}
      {/**<h6>Select Categories</h6>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <Dropdown/>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <Dropdown/>
                    </div>
                    <div className="col-lg-4 col-md-4">
                       <Dropdown/>
                    </div>
                </div>
            */}
    </div>
  );
};

export default Description;
