import React, { useState } from "react";
import styles from "./testimonials.module.scss";
import johnPaul from "../../assets/johnpaul.jpg";
import rukayat from "../../assets/rosehbuddy.jpg";
import owen from "../../assets/plavehbuddy.jpg";
import sylvia from "../../assets/sylvia.jpeg";
import leftQuote from "../../assets/quotesstart.jpg"
import rightQuote from "../../assets/quoteend.jpg"

type StaticTestimonials = {
  name: string;
  img: string;
  text: string;
  bg?: string;
};

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState<StaticTestimonials[]>([
    {
      name: "Rukayat Bukoye",
      img: rukayat,
      text: "Finding the perfect home used to be stressful, but Homebuddy made it seamless. The smart home compatibility feature is a game-changer, and I love the solar-powered financing options too. I found my dream home in just a few clicks!",
      bg: "card-1",
    },
    {
      name: "John Paul",
      img: johnPaul,
      text: "As an agent, Homebuddy has been a fantastic partner. The ability to consolidate my listings in one view has boosted my client interactions and improved my sales process. It is a must-have website for any real estate professional!",
      bg: "card-2",
    },
    {
      name: "Owen Odigie",
      img: owen,
      text: "I had a question about a property, and the Homebuddy team responded promptly. Star rating section for agents and their properties made me feel heard and valued as a user. Highly recommend this platform to everyone!",
      bg: "card-3",
    },
    {
      name: "Sylvia Daniel",
      img: sylvia,
      text: "As an international investor, the multi-currency listing feature was exactly what I needed. Browsing properties in USD saved me time and effort. Homebuddy truly understands its global audience!",
      bg: "card-1",
    },
  ]);

  type TestimonialCardType = StaticTestimonials & { index: number };

  const onClickButton = (direction: "L" | "R") => {
    if (direction === "L") {
      setTestimonials([testimonials[3], ...testimonials.slice(0, 3)]);
    } else {
      setTestimonials([...testimonials.slice(1), testimonials[0]]);
    }
  };

  const TestimonialCard = ({
    name,
    img,
    text,
    index,
    bg,
  }: TestimonialCardType) => {
    return (
      <div
        className={`${styles.card} ${styles[bg as string]} ${
          index === 1 ? styles["card-center"] : styles["card-opaque"]
        }`}
      >
        <div className={styles["quote-container"]}>
          <img
            className={styles["left-quote"]}
            src={leftQuote}
            alt="Left Quote Mark"
          />
          <span className={styles["card-text"]}>{text}</span>
          
          <img
            className={styles["right-quote"]}
            src={rightQuote}
            alt="Right Quote Mark"
          />
        </div>
        <div className={styles["profile-container"]}>
          <div className={styles["profile-img-con"]}>
            <img src={img} className={styles["profile-img"]} />
          </div>
          <span className={styles["profile-text"]}>{name}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles["testimonial-container"]}>
      <div className={styles["test-header"]}>
        <span className={styles["test-header-txt"]}>Testimonials</span>
      </div>

      <span className={styles["header"]}>
        Here’s Why Our Customers Trust Us
      </span>

      <div className={styles["cards-container"]}>
        {testimonials?.map((testimony, index) => (
          <TestimonialCard key={testimony.name} {...testimony} index={index} />
        ))}
      </div>

      <div className={styles["button-container"]}>
        <div
          className={`${styles.button} ${styles["button-none"]}`}
          role="button"
          onClick={() => onClickButton("L")}
        >
          <span className={styles["button-text"]}>{"<"}</span>
        </div>
        <div
          className={`${styles.button} ${styles["button-more"]}`}
          role="button"
          onClick={() => onClickButton("R")}
        >
          <span className={styles["button-text"]}>{">"}</span>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
